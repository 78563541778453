import { mutatorAction } from 'satcheljs';

type ArrayFieldKey<T, K extends keyof T> = T[K] extends any[] ? K : never;

type ArrayElements<T> = {
    [P in keyof T as ArrayFieldKey<T, P>]: T[P] extends (infer U)[] ? U : undefined;
};

type ArrayFieldKeys<T> = keyof ArrayElements<T>;

export type ArrayAddMutators<T, K extends ArrayFieldKeys<T>> = {
    [P in K as `addTo${Capitalize<string & P>}`]: (
        newValue: ArrayElements<T>[P],
        index?: number
    ) => void;
};

export type ArrayRemoveMutators<T, K extends ArrayFieldKeys<T>> = {
    [P in K as `removeFrom${Capitalize<string & P>}`]: (index: number) => void;
};

export type ArrayUpdateMutators<T, K extends ArrayFieldKeys<T>> = {
    [P in K as `updateIn${Capitalize<string & P>}`]: (
        index: number,
        partialUpdates: Partial<ArrayElements<T>[K]>
    ) => void;
};

export default function createArrayMutators<T, K extends ArrayFieldKeys<T>>(
    storeName: string,
    getStore: () => T,
    arrayMutationFields: K[]
): ArrayAddMutators<T, K> & ArrayRemoveMutators<T, K> & ArrayUpdateMutators<T, K> {
    return arrayMutationFields.reduce(
        (arrayMutators, keyValue) => {
            const key = keyValue as string;
            const capitalizedPropertyName = key[0].toUpperCase() + key.substr(1);
            /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
             * Baseline. DO NOT COPY AND PASTE!
             *	> Function should only be invoked in module scope */
            arrayMutators['addTo' + capitalizedPropertyName] = mutatorAction(
                storeName + '_ADD_TO_' + key.toUpperCase(),
                (newValue: unknown, index?: number) => {
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS7053 (43,35): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    // @ts-expect-error
                    const valueList = getStore()[key];
                    if (index !== undefined) {
                        if (valueList.length > index) {
                            valueList.splice(index, 0, newValue);
                        } else {
                            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                             * Error constructor names can only be a string literals.
                             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                            throw new Error(
                                `Invalid index for remove in array field, array length:${valueList.length}, index: ${index}`
                            );
                        }
                    } else {
                        valueList.push(newValue);
                    }
                }
            );

            /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
             * Baseline. DO NOT COPY AND PASTE!
             *	> Function should only be invoked in module scope */
            arrayMutators['removeFrom' + capitalizedPropertyName] = mutatorAction(
                storeName + '_REMOVE_FROM_' + key.toUpperCase(),
                (index: number) => {
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS7053 (64,35): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    // @ts-expect-error
                    const valueList = getStore()[key];
                    if (valueList.length > index) {
                        valueList.splice(index, 1);
                    } else {
                        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                         * Error constructor names can only be a string literals.
                         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                        throw new Error(
                            `Invalid index for remove in array field, array length:${valueList.length}, index: ${index}`
                        );
                    }
                }
            );

            /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
             * Baseline. DO NOT COPY AND PASTE!
             *	> Function should only be invoked in module scope */
            arrayMutators['updateIn' + capitalizedPropertyName] = mutatorAction(
                storeName + '_UPDATE_IN_' + key.toUpperCase(),
                (index: number, partialUpdates: unknown) => {
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS7053 (81,35): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    // @ts-expect-error
                    const valueList = getStore()[key];
                    if (valueList.length > index) {
                        Object.assign(valueList[index], partialUpdates);
                    } else {
                        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                         * Error constructor names can only be a string literals.
                         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                        throw new Error(
                            `Invalid index for update in array field, array length:${valueList.length}, index: ${index}`
                        );
                    }
                }
            );

            return arrayMutators;
        },
        {} as {
            [key: string]: (...args: any[]) => void;
        }
    ) as ArrayAddMutators<T, K> & ArrayRemoveMutators<T, K> & ArrayUpdateMutators<T, K>;
}
