import { mutatorAction } from 'satcheljs';

export type Getters<T> = {
    [K in keyof T as `get${Capitalize<string & K>}`]: () => T[K];
};

export type Setters<T> = {
    [K in keyof T as `set${Capitalize<string & K>}`]: (newValue: T[K]) => void;
};

/**
 * This function returns getter and setter accessors for a given satchel store.
 * Setters are implemented as mutator-actions.
 *
 * @param storeName
 * Name of the store. Used to construct the name of the mutator actions.
 *
 * @param getStore
 * Function that returns a satchel store or one of its observable objects.
 *
 * @returns
 * Getters and setters for each field in the store.
 */
export default function createAccessors<T extends Record<string, unknown>>(
    storeName: string,
    getStore: () => T
): Getters<T> & Setters<T> {
    return Object.keys(getStore()).reduce(
        (gettersAndSetters, key) => {
            const capitalizedPropertyName = key[0].toUpperCase() + key.substr(1);
            gettersAndSetters['get' + capitalizedPropertyName] = () => getStore()[key];
            /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
             * Baseline. DO NOT COPY AND PASTE!
             *	> Function should only be invoked in module scope */
            gettersAndSetters['set' + capitalizedPropertyName] = mutatorAction(
                storeName + '_SET_' + key.toUpperCase(),
                (newValue: any) => (getStore()[key as keyof T] = newValue)
            );
            return gettersAndSetters;
        },
        {} as {
            [key: string]: (...args: any[]) => any;
        }
    ) as Getters<T> & Setters<T>;
}
