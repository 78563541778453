import { isMobileScreenSizeEnabled } from './isMobileScreenSizeEnabled';
import { getApplicationSettings } from 'owa-application-settings';
import { getBrowserWidth } from 'owa-config';
import { isAccountFeatureEnabled, isFeatureEnabled } from 'owa-feature-flags';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isPlacesPremiumEnabled } from 'places-settings';

import type { MailboxInfo } from 'owa-client-types';
import type { Features } from 'owa-graph-schema';

// Check if the user qualifies as an admin for space management.
export function isSpaceManagementAdminTabEnabled() {
    const mailboxInfo = getModuleContextMailboxInfo();
    // As a first iteration admin is gated by a flight. We will implement rbacs checks later.
    const hasAdminAccess = isFeatureEnabled('msplaces-space-management-page', mailboxInfo);

    const windowWidth = getBrowserWidth();
    const mobileScreenSizeEnabled = isMobileScreenSizeEnabled(windowWidth);

    const isAdminEnabled =
        hasAdminAccess && isPlacesPremiumEnabled(mailboxInfo) && mobileScreenSizeEnabled;

    return devOverrideAdminEnabled(isAdminEnabled, mailboxInfo);
}

// Checks if the user qualifies as an admin for space analytics
export function isSpaceAnalyticAdminTabEnabled(featureAccess: (Features | null)[]) {
    const mailboxInfo = getModuleContextMailboxInfo();
    const isAdminFlightEnabled = getApplicationSettings(
        'MsPlaces',
        mailboxInfo
    ).enableAdminAvailableOptions;
    const hasAdminAccess: boolean =
        featureAccess?.some((access: Features | null) => access === 'AllAdminFeatures') || false;
    const windowWidth = getBrowserWidth();
    const mobileScreenSizeEnabled = isMobileScreenSizeEnabled(windowWidth);

    const isAdminEnabled =
        hasAdminAccess &&
        isAdminFlightEnabled &&
        isPlacesPremiumEnabled(mailboxInfo) &&
        mobileScreenSizeEnabled;

    return devOverrideAdminEnabled(isAdminEnabled, mailboxInfo);
}

// Overrides the admin enabled state for dev purposes
function devOverrideAdminEnabled(isAdminEnabled: boolean, mailboxInfo: MailboxInfo) {
    return isAccountFeatureEnabled('places-dev-settings-admin-enabled', mailboxInfo)
        ? true
        : isAccountFeatureEnabled('places-dev-settings-admin-disabled', mailboxInfo)
        ? false
        : isAdminEnabled;
}

// Checks if any admin experience should be enabled
export function isAdminTabEnabled(featureAccess: (Features | null)[]) {
    return isSpaceManagementAdminTabEnabled() || isSpaceAnalyticAdminTabEnabled(featureAccess);
}
