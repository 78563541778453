import { PerformanceDatapoint } from 'owa-analytics';
import type { OwaDate } from 'owa-datetime';
import { getISODateString } from 'owa-datetime';

export enum WorkPlansDailyPeekPerfWaterfallPoint {
    WorkPlansDailyPeekStartRender = 1,
    WorkLocationsSegmentRendered = 2,
    OthersInOfficeStartRender = 3,
    OthersInOfficeDataReturned = 4,
    OthersInOfficeRendered = 5,
    SuggestionsStartRender = 6,
    SuggestionsRendered = 7,
}

let workPlansDailyPeekPerfDataPoint: PerformanceDatapoint | null = null;
let includeOthersInOfficeInPerfWaterfall = false;
let includeSuggestionsInPerfWaterfall = false;

export function createWorkPlansDailyPeekPerfDatapoint(
    dateOfWorkPlansDailyPeek: OwaDate,
    customStartTimeForPerfWaterfall: number
) {
    if (!workPlansDailyPeekPerfDataPoint) {
        workPlansDailyPeekPerfDataPoint = new PerformanceDatapoint(
            'WorkPlansDailyPeekPerfWaterfall',
            {
                customStartTime: customStartTimeForPerfWaterfall,
            }
        );
        const workPlansDailyPeekDate = getISODateString(dateOfWorkPlansDailyPeek);
        workPlansDailyPeekPerfDataPoint.addCustomData({ workPlansDailyPeekDate });
    }
}

export function setWorkPlansDailyPeekPerfWaterfallState(
    point: WorkPlansDailyPeekPerfWaterfallPoint
) {
    workPlansDailyPeekPerfDataPoint?.addToCustomWaterfall(
        point,
        WorkPlansDailyPeekPerfWaterfallPoint[point],
        true /*discardIfDefined*/
    );

    endDatapointIfAllRequiredWaterfallPointsHit();
}

export function setIncludeOthersInOfficeInPerfWaterfall() {
    includeOthersInOfficeInPerfWaterfall = true;
}

export function setIncludeSuggestionsInPerfWaterfall() {
    includeSuggestionsInPerfWaterfall = true;
}

function isOthersInOfficeCheckpointHitIfExists(): boolean {
    if (!workPlansDailyPeekPerfDataPoint) {
        return false;
    }
    // If OthersInOffice doesn't exist in this peek, then doen't block the end of the datapoint on this checkpoint
    if (!includeOthersInOfficeInPerfWaterfall) {
        return true;
    }
    return workPlansDailyPeekPerfDataPoint.isWaterfallCheckpointDefined(
        WorkPlansDailyPeekPerfWaterfallPoint.OthersInOfficeRendered
    );
}

function isSuggestionsCheckpointHitIfExists(): boolean {
    if (!workPlansDailyPeekPerfDataPoint) {
        return false;
    }
    // If Suggestions doesn't exist in this peek, then doen't block the end of the datapoint on this checkpoint
    if (!includeSuggestionsInPerfWaterfall) {
        return true;
    }
    return workPlansDailyPeekPerfDataPoint.isWaterfallCheckpointDefined(
        WorkPlansDailyPeekPerfWaterfallPoint.SuggestionsRendered
    );
}

const endDatapointIfAllRequiredWaterfallPointsHit = () => {
    if (
        workPlansDailyPeekPerfDataPoint &&
        !workPlansDailyPeekPerfDataPoint.hasEnded &&
        workPlansDailyPeekPerfDataPoint.isWaterfallCheckpointDefined(
            WorkPlansDailyPeekPerfWaterfallPoint.WorkPlansDailyPeekStartRender
        ) &&
        workPlansDailyPeekPerfDataPoint.isWaterfallCheckpointDefined(
            WorkPlansDailyPeekPerfWaterfallPoint.WorkLocationsSegmentRendered
        ) &&
        isOthersInOfficeCheckpointHitIfExists() &&
        isSuggestionsCheckpointHitIfExists()
    ) {
        workPlansDailyPeekPerfDataPoint.end();
        workPlansDailyPeekPerfDataPoint = null;
    }
};
