import type { DateRange } from 'owa-datetime-utils';
import { differenceInDays, getDay } from 'owa-datetime';
import type { OwaDate } from 'owa-datetime';
import { getSuccessiveOwaDates } from 'owa-datetime-utils/lib/getSuccessiveOwaDates';

import type { DayOfWeek } from '@fluentui/date-time-utilities';

/**
 * Gets the array of days from a date range
 */
export default function getWorkFilteredOwaDateArray(
    currentCalendarDateRangeView: DateRange,
    userWorkWeekDays?: DayOfWeek[]
): OwaDate[] {
    const daysOfWeekOnCurrentDateRange: OwaDate[] = getSuccessiveOwaDates(
        currentCalendarDateRangeView.start,
        differenceInDays(currentCalendarDateRangeView.end, currentCalendarDateRangeView.start) + 1
    );
    if (userWorkWeekDays) {
        return userWorkWeekDays.map(
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            userWorkDay => daysOfWeekOnCurrentDateRange.find(date => getDay(date) === userWorkDay)!
        );
    }
    return daysOfWeekOnCurrentDateRange;
}
