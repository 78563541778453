import type { OwaDate } from 'owa-datetime';
import { addDays } from 'owa-datetime';

/**
 * Builds and returns an array of OwaDates starting as the provided startDate for the provided (count) number of days
 *
 * @param startDate
 * @param count Number of successive days to return
 */
export function getSuccessiveOwaDates(startDate: OwaDate, count: number): OwaDate[] {
    const result: OwaDate[] = [];

    for (let i = 0; i < count; i++) {
        result.push(startDate);
        startDate = addDays(startDate, 1);
    }

    return result;
}
