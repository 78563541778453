import type { DayOfWeek } from '@fluentui/date-time-utilities';
import React from 'react';
import setOccurringFWHInStore from './setOccurringFWHInStore';
import { DateRangeType } from '@fluentui/date-time-utilities';
import { getOccurringFilteredFlexibleWorkingHours } from './getFilteredFlexibleWorkingHours';
import { getUserWeekStartDay } from './getUserWeekStartDay';
import getWorkFilteredOwaDateArray from './getWorkFilteredOwaDateArray';

import type { DateRange } from 'owa-datetime-utils';
import type { FlexibleWorkingHoursView } from 'owa-graph-schema';

import type { OwaDate } from 'owa-datetime';

const defaultWorkDays = [1, 2, 3, 4, 5];

export default function useFlexibleWorkingHoursAndWorkDates(
    dateRange: DateRange,
    flexibleWorkingHours: FlexibleWorkingHoursView[],
    dateRangeType?: DateRangeType
) {
    const {
        flexibleWorkingHoursData,
        workDaysFromFilteredList,
        workDates,
    }: {
        flexibleWorkingHoursData: FlexibleWorkingHoursView[][];
        workDaysFromFilteredList: DayOfWeek[];
        workDates: OwaDate[];
    } = React.useMemo(() => {
        return getFlexibleWorkingHoursAndWorkDates(dateRange, flexibleWorkingHours, dateRangeType);
    }, [dateRange, flexibleWorkingHours, dateRangeType]);

    React.useEffect(() => {
        setOccurringFWHInStore(flexibleWorkingHoursData);
    }, [flexibleWorkingHoursData]);

    return {
        flexibleWorkingHoursData,
        workDaysFromFilteredList,
        workDates,
    };
}

function getFlexibleWorkingHoursAndWorkDates(
    dateRange: DateRange,
    flexibleWorkingHours: FlexibleWorkingHoursView[],
    dateRangeType?: DateRangeType
) {
    const weekStartDay = getUserWeekStartDay();
    let occuringFlexibleWorkingHours: FlexibleWorkingHoursView[][] =
        getOccurringFilteredFlexibleWorkingHours(flexibleWorkingHours);

    occuringFlexibleWorkingHours = occuringFlexibleWorkingHours
        .slice(weekStartDay)
        .concat(occuringFlexibleWorkingHours.slice(0, weekStartDay));

    let occuringWorkDaysFromFilteredList: DayOfWeek[] = occuringFlexibleWorkingHours
        .map((array, index) => {
            if (array.length > 0) {
                return (index + weekStartDay) % occuringFlexibleWorkingHours.length;
            }
            return undefined;
        })
        .filter(dayOfWeek => dayOfWeek !== undefined)
        .map(dayOfWeek => {
            // This is functionally equivalent to a non-null assertion.
            return dayOfWeek as DayOfWeek;
        });

    occuringWorkDaysFromFilteredList =
        occuringWorkDaysFromFilteredList.length > 0
            ? occuringWorkDaysFromFilteredList
            : defaultWorkDays;

    const occuringWorkDates =
        dateRangeType === DateRangeType.Day
            ? [dateRange.start]
            : getWorkFilteredOwaDateArray(dateRange, occuringWorkDaysFromFilteredList);

    return {
        flexibleWorkingHoursData: occuringFlexibleWorkingHours,
        workDaysFromFilteredList: occuringWorkDaysFromFilteredList,
        workDates: occuringWorkDates,
    };
}
